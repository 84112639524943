<template>
  <div class="visitor">
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%;">
        <el-table-column prop="name" label="用户名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号码" width="200"></el-table-column>
        <el-table-column prop="is_enable" label="是否启用" width="120"></el-table-column>
        <el-table-column prop="created" label="添加日期" show-overflow-tooltip></el-table-column>
        <el-table-column prop="chain_name" label="所属学校" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleAlter(scope.row)">{{ scope.row.is_enable === '已启用' ? '禁' : '启' }}用</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" :currentPage="query_info.page" @changePage="changePage" />
  </div>
</template>

<script>
import { aiUserlist, delUser, shopStatus } from '@/request/api';
import MyPagination from "@/components/MyPagination.vue";

export default {
  inject: ["reload"],
  components: {
    MyPagination
  },
  data() {
    return {
      loading: false,
      query_info: {
        limit: 10,
        page: 1
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    async getVisitorList() {
      if (this.loading) return

      this.loading = true
      try {
        const res = await aiUserlist(
            this.query_info.limit,
            this.query_info.page,
            '',
            1,
            5
        )
        this.loading = false
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }
        res.data.data.userList.forEach((element, index, arr) => {
          element.is_enable === 0 ? (arr[index].is_enable = '已启用') : (arr[index].is_enable = '已禁用')
        })

        this.tableData = res.data.data.userList
        this.total = res.data.data.totalNumber
      } catch(error) {
        this.loading = false
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否删除该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delUser({ shop_user_id: row.shop_user_id })
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.$message({ type: "success", message: "删除成功！" });
        this.reload();
      }).catch(() => {
        this.$message({ type: "info", message: "已取消删除" });
      })
    },
    // 启用或禁用
    async handleAlter(row) {
      try {
        const res = await shopStatus({
          shop_user_id: row.shop_user_id,
          is_enable: row.is_enable === '已启用' ? 1 : 0
        })
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.getVisitorList()
        this.$message({ type: "success", message: "修改成功！" });
        this.reload()
      } catch(error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 取分页页码
    changePage(page) {
      this.query_info.page = page
      this.tableData = []
      this.getVisitorList()
    }
  },
  mounted() {
    this.getVisitorList()
  }
}
</script>

<style lang="less" scoped>
.visitor {
  padding: 20px;
}
</style>